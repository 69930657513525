// Variables
:root {
  // HSL: https://www.sarasoueidan.com/blog/hex-rgb-to-hsl/
  --color: hsl(0, 0%, 0%);
  --colorbackground: hsl(0, 0%, 100%);
  --colorlink: #2980B9;
  --colorlinkhover: #1ABC9C;
  --colorlinkvisited: #8E44AD;
}

@custom-selector :--heading h1, h2, h3, h4, h5, h6;

// Styles
html {
  box-sizing: border-box;
  font-size: 100%; // Usually 16px
  color: var(--color);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; // http://markdotto.com/2018/02/07/github-system-fonts/
  font-size: calc(0.875rem + 0.3vw + 0.3vh); // Usually 14px + …
  line-height: 1.6;
  background-color: var(--colorbackground);
}

pre {
  overflow-x: auto; // kinda borrowed from normalize.css before 4.x, primarily for mobile
  tab-size: 4;
}

time {
  cursor: help;
}

.side-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh;

  h1 {
    font-size: calc(3rem + 1vw); // Usually 48px + …
    line-height: normal;
    font-family: 'Lobster Two', cursive;
    font-weight: 700;
    font-style: italic;
    text-align: center;
  }

  nav {
    border-style: dotted;
    border-width: 1px 0;
    border-color: lightgrey;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
    }

    li {
      display: inline-block;
    }

    li:not(:last-of-type) {
      margin-right: calc(8px + 0.5vw);
    }

    a {
      padding: 0.25em 0.5em;
      display: flex;
      border-radius: 2px;
      font-size: calc(0.875rem + 0.2vw + 0.2vh); // Usually 14px + …
    }
  }
}

.columns {
  display: flex;
  justify-content: center;

  img {
    vertical-align: middle;
  }

  > *:not(:last-of-type) {
    margin-right: 12px;
  }

  > *:not(:first-of-type) {
    margin-left: 0;
  }
}

// OLD BUT RELEVANT STUFF BELOW
[class^="entry-"]:not(.entry-title) > *:not(.box-flex-image):not(.columns):not(.highlight) {
  margin-right: auto;
  margin-left: auto;
  max-width: 75ch;
  padding: 0 12px;
}

.entry-content p, .entry-content ul, .entry-content ol, .entry-content blockquote, .entry-content dl {
  min-width: 35ch;
  /* text-align: justify; TODO maybe? */
}

.entry-title {
  min-width: 15ch;
  max-width: 55ch !important; /* TODO fix!? */
  font-size: calc(30px + 0.4vw);
  line-height: 1.2;
}

.box-flex-image {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  margin: 0; // for <figure>
}

.box-flex-image img {
  vertical-align: middle; /* from https://github.com/bendc/frontend-guidelines#flow */
}

.box-flex-image a, img {
  max-width: 100%;
}

.box-flex-image a, img:not(.full-height) {
  max-height: 90vh;
}

img {
  width: auto;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 150ms ease-in-out 0s;
}

.entry-content a {
  text-decoration: underline;
  color: var(--colorlink);
}

.entry-content a:visited {
  color: var(--colorlinkvisited);
}

a:visited:hover, a:hover {
  color: var(--colorlinkhover);
}

nav a:visited:hover, nav a:hover {
  background-color: var(--colorlinkhover);
  color: var(--colorbackground);
}

a[href^="http://"]:not([href*="localhost"]):after,
a[href^="https://"]:not([href*="localhost"]):after {
  /* content: url("/wp-content/themes/swp-wpperfect/iconic/smart/external-link.svg"); */
}

:--heading {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-align: center;
}

article:not(:last-of-type) {
  margin-bottom: 8vh;
}

.entry-footer {
  color: darkgrey;
  text-align: center;
}

.slideshow-wrapper {
  max-width: initial !important; /* TODO */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.slideshow-wrapper a {
  height: 30vh;
  margin: 0.5vh 0.5vh;
}

.slideshow-wrapper a img {
  max-width: 100%;
  height: 100%;
}

.highlight {
  max-width: 100ch;
  margin: 1em auto;

  pre {
    margin: 0;
    padding: 12px;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color: hsl(0, 0%, 100%);
    --colorbackground: hsl(0, 0%, 13%);
    --colorlinkhover: #0A89BB;
  }
}
